<template>
  <v-container fluid>
    <TableViewComponent
      title="Table natures remboursement"
      @addItemEvent="onAddItem()"
      addItemLabel="ajouter une nature de remboursement"
      itemLabel="nature de remboursement"
      itemsLabel="natures de remboursement"
      :items="refundTypes"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette nature ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import RefundTypeService from "@/service/dictionary/refund_type_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableRefundTypes",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**le service d'accès au APIs. */
      refundTypesService: null,

      /**la liste des motifs maladie */
      refundTypes: [],
    };
  },
  methods: {
    onAddItem() {
      this.$router.push(routes.refundTypes.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.refundTypes = [];

        // Récupération des natures de remboursement
        let refundTypes = await this.refundTypesService.getAllRefundTypes();

        refundTypes.forEach((r) => {
          let type = JSON.parse(JSON.stringify(r));

          //action de consultation
          type.view = () => {
            this.$router.push(
              routes.refundTypes.view.root + "/" + type.id
            );
          };

          // action de modification
          type.edit = () => {
            this.$router.push(routes.refundTypes.edit.root + "/" + type.id);
          };

          //action de suppression
          type.delete = async () => {
            try {
              await this.refundTypesService.delete(type.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de la nature de remboursement : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.refundTypes.push(type);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Nom",
          align: "start",
          sortable: true,
          value: "label",
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditDictionary];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditDictionary, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadDictionary];
    },
  },
  mounted() {
    // Instanciation de la classe service
    this.refundTypesService = new RefundTypeService(this.$api);
    // Chargement des datas
    this.load();
  },
};
</script>

<style></style>
